<template>
    <div id="combo-app" class="modal" v-bind:class="{ 'is-active': isOpen }">
        <div class="modal-background" v-on:click="close"></div>
        <div v-if="data" class="modal-content">
            <div class="card">
                <div class="card-content">
                    <h2 class="title">Calculadora de combos</h2>
                    <p v-if="takeoffs[0] == null" class="subtitle">
                        Selecciona el primer enlairament
                    </p>
                    <div class="box">
                        <div
                            class="select is-medium is-primary mb-3"
                            v-for="(takeoff, index) in takeoffs"
                            v-bind:key="index"
                        >
                            <select v-model="takeoffs[index]">
                                <option selected disabled>Enlairaments</option>
                                <template
                                    v-for="peak in getPeaksForIndex(index)"
                                >
                                    <option
                                        v-bind:key="peak.id"
                                        v-bind:value="peak"
                                    >
                                        {{ peak.name }}
                                    </option>
                                </template>
                            </select>
                        </div>
                    </div>
                </div>
                <footer class="card-footer">
                    <p href="#" class="card-footer-item">
                        <strong>Puntuació {{ score }}pt</strong>
                    </p>
                    <p
                        v-on:click="clear"
                        href="#"
                        class="has-text-danger card-footer-item"
                    >
                        Esborrar
                    </p>
                </footer>
            </div>
        </div>
        <button
            v-on:click="close"
            class="modal-close is-large"
            aria-label="close"
        ></button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            data: null,
            isOpen: false,
            takeoffs: [null],
        };
    },
    computed: {
        score: function () {
            if (this.takeoffs.length < 3) return 0;

            const data = this.data;

            return this.takeoffs.reduce(function (
                previous,
                currentTakeoff,
                index,
                takeoffs
            ) {
                if (currentTakeoff == null) return previous;

                if (index == 0) return previous + currentTakeoff.score;

                const combo = data.combos.find(
                    (c) =>
                        c.starting_peak_id == takeoffs[index - 1].id &&
                        c.ending_peak_id == currentTakeoff.id
                );

                return previous + combo.score + currentTakeoff.score;
            },
            0);
        },
    },
    methods: {
        open() {
            this.isOpen = true;
        },
        close() {
            this.isOpen = false;
        },
        clear() {
            this.takeoffs = [null];
        },
        getPeaksForIndex(index) {
            //if (index == 0 && this.takeoffs.length == 1) return this.data.peaks;

            return this.data.peaks.filter(
                (peak) =>
                    // we exclude next peak
                    (this.takeoffs[index + 1] == null ||
                        this.takeoffs[index + 1].id != peak.id) &&
                    // we exclude previous peak
                    (!this.takeoffs[index - 1] ||
                        this.takeoffs[index - 1].id != peak.id)
            );
        },
    },
    mounted() {
        this.$http.get("/api/combo-settings").then((response) => {
            this.data = response.data;
        });
    },
    watch: {
        takeoffs() {
            if (this.takeoffs[this.takeoffs.length - 1] != null) {
                this.takeoffs.push(null);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
#combo-app {
    right: 100vw;
    transition-property: right;
    transition-duration: 500ms;
    display: flex;

    &.is-active {
        right: 50vw;

        @media (max-width: 1000px) {
            right: 0;
        }

        .modal-close {
            display: flex;
        }

        .modal-content {
            display: block;
        }
    }

    .modal-close {
        display: none;
        position: absolute;
    }

    .modal-content {
        min-height: 50%;
        display: none;
        width: 80%;
        background: white;

        @media (max-width: 1000px) {
            width: 90%;
        }
    }

    select,
    .select {
        width: 100%;
    }

    .card {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .card-content {
        flex-grow: 1;
    }
}
</style>
